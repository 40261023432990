<template>
  <div class="feedback">
    <transition name="slide-fade">
      <div v-if="amendOpinion" class="revision-container">
        <div v-if="caseDetail['cureType'] === 'E'" class="project-tabs">
          <div
              :class="[nowProjectType === 1 ? 'left-active' : 'left-defualt']"
              @click="nowProjectType = 1"
          >
            {{ $t("threeD.jzfa") }}
          </div>
          <div
              :class="[nowProjectType === 2 ? 'right-active' : 'right-default']"
              @click="nowProjectType = 2"
          >
            {{ $t("threeD.xffa") }}
          </div>
          <!--          <div class="close-drawer" @click="amendOpinion = false">-->
          <i class="el-icon-close close-drawer" @click="amendOpinion = false" />
          <!--          </div>-->
        </div>
        <div
            :class="
            caseDetail['cureType'] === 'E' && nowProjectType === 1
              ? 'revision-content-hide-radio'
              : 'revision-content'
          "
        >
          <div class="flex-content">
            <div class="cure-plan-name">
              <div
                  :title="get(cureNoteDetail, 'userInfo.realName')"
                  class="user-name"
              >
                {{ get(cureNoteDetail, "userInfo.realName") }}
              </div>
              <span>{{
                  `${$t("casesDetail.casesDetail.tdzlfa")}${get(
                      cureNoteDetail,
                      "number"
                  )}`
                }}</span>
            </div>
            <div class="create-design-time">
              {{ get(cureNoteDetail, "generateDatetime") }}
            </div>
            <div
                class="design-opinion"
                v-html="
                nowProjectType === 1
                  ? cureNoteDetail['note']
                  : cureNoteDetail['veneerNote']
              "
            >
              <!--              {{-->
              <!--                nowProjectType === 1-->
              <!--                  ? cureNoteDetail["note"]-->
              <!--                  : cureNoteDetail["veneerNote"]-->
              <!--              }}-->
            </div>
            <div class="divider-line"></div>
            <div class="opinion-description">
              <div class="opinion-description-title">
                <div class="flex-left">
                  <img alt="" src="@/common/imgs/3d/opinion_title.png" />
                  <p>{{ $t("threeD.yjms") }}</p>
                </div>
                <div v-if="canEdit" class="flex-right">
                  <div class="edit-3d-control" @click="changeViewType">
                    {{ isEdit ? $t("threeD.gbdhxg") : $t("threeD.threeddhxg") }}
                  </div>
                  <div class="help-icon" @click="toHelp"></div>
                </div>
              </div>
              <div v-if="mod3DRecord" class="mod-3d-record">
                <div class="record-tips">{{ $t("threeD.rjjzdjl") }}</div>
                <div class="record-info">{{ mod3DRecord }}</div>
              </div>
              <div class="opinion-description-content">
                <textarea
                    v-if="nowProjectType === 1"
                    v-model="operateRemark"
                    :placeholder="$t('threeD.qzcsrjzfayj')"
                    class="edit-revision"
                ></textarea>
                <textarea
                    v-if="nowProjectType === 2"
                    v-model="veneerRemark"
                    :placeholder="$t('threeD.qzcsrxffayj')"
                    class="edit-revision"
                ></textarea>
              </div>
              <p class="submit-tips">{{ $t("threeD.ntjd") }}</p>
              <p
                  v-if="nowPort === 'RDT' && cureNoteDetail.passFlag === '0'"
                  class="irdt-tips"
              >
                {{ $t("casesDetail.casesChildren.amendments.zcwrxtj") }}
              </p>
            </div>
          </div>
          <div class="flex-footer">
            <div
                v-if="nowPort === 'RDT'"
                class="working-area"
                @click="amendOpinion = false"
            >
              {{ $t("common.common.qx") }}
            </div>
            <div v-else class="working-area" @click="stagingData('0')">
              {{ $t("cases.casesCreate.zc") }}
            </div>
            <div
                class="submit-revision"
                @click="stagingData(nowPort === 'RDT' ? '0' : '2')"
            >
              {{
                nowPort === "RDT"
                    ? $t("cases.casesCreate.zc")
                    : $t("casesDetail.casesChildren.reviewPlan.tjxgyj")
              }}
            </div>
          </div>
        </div>
        <i
            v-if="caseDetail['cureType'] !== 'E'"
            class="el-icon-close close-revision"
            @click="amendOpinion = false"
        ></i>
      </div>
    </transition>
    <div class="feedback-content">
      <div class="user_message">
        <div
            :style="{
            backgroundImage: `url(${$PicPrefix}${get(
              cureNoteDetail,
              'userInfo.photo'
            )})`
          }"
            class="user_pic"
        ></div>
        <div class="user_content">
          <div class="user_name txt-ellipsis">
            {{ get(cureNoteDetail, "userInfo.realName") }}
          </div>
          <div class="user_age">
            <svg aria-hidden="true" class="icon user_info_icon">
              <use xlink:href="#icon-nianling"></use>
            </svg>
            <span class="mr10"
            >{{ get(cureNoteDetail, "userInfo.age") }}
              {{ $t("cases.cases.s") }}</span
            >
          </div>
        </div>
      </div>
      <p class="case-number">
        {{ $t("cases.cases.blh") }}：{{ get(caseDetail, "caseNumber") }}
      </p>
      <p class="curenote-name">
        {{
          schemeType === "target"
              ? $t("threeD.mbfa")
              : caseDetail["cureType"] === "F"
                  ? $t("casesDetail.casesDetail.mxfa")
                  : $t("casesDetail.casesDetail.tdzlfa")
        }}
        {{ cureNoteDetail["number"] }}
      </p>
      <!--      v-if="'toothBatchPlasticSheet' in cureNoteDetail"-->
      <p class="plastics-name">
        {{ $t("threeD.mpcz") }}：{{ renderPlasticsNames }}
        <span
            v-if="
            nowPort === 'DOCTOR' &&
              cureNoteDetail['passFlag'] === '0' &&
              ['to_doctor_confirm_plan'].includes(
                caseDetail['curePlanInfo'].status
              )
          "
            class="edit-batch"
            @click="callModalShow('edit')"
        >{{ $t("common.message.xg") }}</span
        >
        <span class="batch-info" @click="callModalShow('info')">{{
            $t("common.message.xq")
          }}</span>
      </p>
      <div v-if="caseDetail['cureType'] === 'E'" class="project-tabs">
        <div
            :class="[nowProjectType === 1 ? 'left-active' : 'left-default']"
            @click="nowProjectType = 1"
        >
          {{ $t("threeD.jzfa") }}
        </div>
        <div
            :class="[nowProjectType === 2 ? 'right-active' : 'right-default']"
            @click="nowProjectType = 2"
        >
          {{ $t("threeD.xffa") }}
        </div>
      </div>
      <div
          :class="[
          caseDetail['cureType'] !== 'E'
            ? 'default-project-text'
            : nowProjectType === 1
            ? 'left-active'
            : 'right-active'
        ]"
          class="project-text"
          v-html="
          nowProjectType === 1
            ? cureNoteDetail['note']
            : cureNoteDetail['veneerNote']
        "
      >
        <!--        {{-->
        <!--          nowProjectType === 1?cureNoteDetail["note"]:cureNoteDetail["veneerNote"]-->
        <!--        }}-->
      </div>
    </div>
    <div v-if="buttonShow" class="feedback-footer">
      <div
          v-if="amendmentShow"
          class="submit-revision"
          @click="amendOpinion = true"
      >
        {{ $t("casesDetail.casesChildren.reviewPlan.tjxgyj") }}
      </div>
      <div
          v-if="approveShow"
          class="approval-programme"
          @click="callModalShow('submit')"
      >
        {{
          schemeType === "target"
              ? $t("casesDetail.casesChildren.reviewPlan.qrcfa")
              : $t("casesDetail.casesChildren.reviewPlan.pzcfa")
        }}
      </div>
    </div>
    <div
        v-if="
        nowPort === 'RDT' &&
          !amendmentShow &&
          'rdtMod3DId' in cureNoteDetail &&
          canEdit
      "
        class="animation-change"
        @click="changeViewType"
    >
      {{ isEdit ? $t("threeD.gbdhxg") : $t("threeD.ckdhxg") }}
    </div>
    <div
        v-if="['SALES', 'DOCTOR'].includes(nowPort) && canEdit && !buttonShow"
        class="animation-change"
        @click="changeViewType"
    >
      {{ isEdit ? $t("threeD.gbdhxg") : $t("threeD.ckdhxg") }}
    </div>
    <transition name="slide-fade">
      <div v-if="showModal" class="modal-mask" @click="showModal = false">
        <div
            :class="
            !['edit', 'info'].includes(modalType)
              ? 'submit-modal'
              : 'plastics-modal'
          "
            @click="e => e.stopPropagation()"
        >
          <div class="modal-header">
            <i class="el-icon-close close-modal" @click="showModal = false"></i>
          </div>
          <div
              v-if="['edit', 'info'].includes(modalType)"
              class="modal-content"
          >
            <div class="bacth-current-title">
              <div style="display: flex;flex: 1">
                <div class="label">{{ $t("braceReissue.mpzh") }}：</div>
                <div class="value">
                  <div style="width: 80px">
                    {{
                      `${$t(
                          "casesDetail.casesChildren.lookWearingRecord.ytzbs"
                      )}`
                    }}
                  </div>
                  <div class="batch_step" style="margin-left: 20px">
                    {{
                      `${$t("cases.createDiagnosis.se")}：${
                          cureNoteDetail["batchInfo"]["maxillary"]
                      }`
                    }}
                  </div>
                  <div>
                    {{
                      `${$t("cases.createDiagnosis.xe")}：${
                          cureNoteDetail["batchInfo"]["mandibular"]
                      }`
                    }}
                  </div>
                </div>
              </div>
              <div
                  style="color:#fcc80e;width: 1rem; cursor:pointer;"
                  @click="changeBatchOpen(false)"
              >
                批次信息详情
              </div>
            </div>
            <div class="batch-content">
              <div
                  v-for="(item, index) in upSteps"
                  :key="index"
                  class="batch-item"
              >
                <div style="width: 80px">
                  {{
                    `${$t(
                        "casesDetail.casesChildren.lookWearingRecord.d"
                    )}${index + 1}${$t(
                        "casesDetail.casesChildren.lookWearingRecord.pc"
                    )}`
                  }}
                </div>
                <div class="batch_step">
                  {{ `${$t("cases.createDiagnosis.se")}：${item}` }}
                </div>
                <div class="plastics">
                  {{ `${$t("casesDetail.casesChildren.casesDoKeep.cz")}：` }}
                  <el-select
                      v-if="
                      item !== '0' &&
                        modalType === 'edit' &&
                        upSheetList[index].split('，').length <= 1
                    "
                      v-model="upList[index][0].plasticSheetId"
                      :placeholder="
                      $t('casesDetail.casesChildren.casesDoKeep.qxzcz')
                    "
                      :popper-append-to-body="false"
                      class="sheet-select"
                      @change="changeBatchSelect('up', index, $event)"
                  >
                    <el-option
                        v-for="(it, idx) in sheetList"
                        :key="idx"
                        :label="it.name"
                        :value="it.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                      v-if="
                      modalType === 'info' ||
                        (modalType === 'edit' &&
                          upSheetList[index].split('，').length > 1)
                    "
                      style="
                      cursor: pointer;
                    "
                      @click="changeBatchOpen(true, index + 1)"
                  >
                    {{ upSheetList[index] }}
                  </span>
                </div>
                <div class="batch_step">
                  {{ `${$t("cases.createDiagnosis.xe")}：${downSteps[index]}` }}
                </div>
                <div class="plastics">
                  {{ `${$t("casesDetail.casesChildren.casesDoKeep.cz")}：` }}

                  <el-select
                      v-if="
                      downSteps[index] !== '0' &&
                        modalType === 'edit' &&
                        downSheetList[index].split('，').length <= 1
                    "
                      v-model="downList[index][0].plasticSheetId"
                      :placeholder="
                      $t('casesDetail.casesChildren.casesDoKeep.qxzcz')
                    "
                      :popper-append-to-body="false"
                      class="sheet-select"
                      @change="changeBatchSelect('down', index, $event)"
                  >
                    <el-option
                        v-for="(it, idx) in sheetList"
                        :key="idx"
                        :label="it.name"
                        :value="it.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                      v-if="
                      modalType === 'info' ||
                        (modalType === 'edit' &&
                          downSheetList[index].split('，').length > 1)
                    "
                      style="
                      cursor: pointer;
                    "
                      @click="changeBatchOpen(true, index + 1)"
                  >
                    {{ downSheetList[index] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="submit-content">
            <img
                alt=""
                src="https://picture.aismile.cn/web-static/aismile.cn/warn-dia-icon.png"
            />
            <div class="submit-title">
              {{ $t("casesDetail.casesChildren.reviewPlan.sfpz3d") }}
            </div>
            <div class="submit-tips">
              {{ `${$t("casesDetail.casesChildren.lookWearingRecord.rnpzh")}` }}
            </div>
            <!--            v-if="'toothBatchPlasticSheet' in cureNoteDetail"-->
            <div class="plastics-wrap">
              <div class="plastics-content">
                <div class="label">{{ $t("braceReissue.mpzh") }}：</div>
                <div :title="renderPlasticsNames" class="value">
                  {{ renderPlasticsNames }}
                </div>
                <div class="control">
                  <span @click="callModalShow('edit')">{{
                      $t("common.message.xg")
                    }}</span>
                  <span @click="callModalShow('info')">{{
                      $t("common.message.xq")
                    }}</span>
                </div>
              </div>
              <div class="plastics-tips">
                <span
                >此膜片组合为方案设计推荐，如需更改可自行选择，方案批准后生效</span
                >
              </div>
            </div>
          </div>
          <div v-if="modalType !== 'submit'" class="batch-tips">
            <span v-if="modalType === 'edit'"
            >此膜片组合为方案设计推荐，如需更改可自行选择，方案批准后生效</span
            >
            <br />
            <span>点击批次信息详情查看具体步骤材质信息</span>
          </div>
          <div class="modal-footer">
            <Button class="cancel" @click="showModal = false">
              {{ $t("common.message.qx") }}
            </Button>
            <Button class="confirm" @click="confirmModal">
              {{ confirmText[modalType] }}
            </Button>
          </div>
        </div>
      </div>
    </transition>
    <notifyMsgCm
        ref="notifyMsgCm"
        :isOldCase="get(cureNoteDetail, 'toothViewVersion') === '0'"
    />
    <BatchDrawer
        ref="batchDrawerRef"
        :cureNoteDetail="batchDetail"
        :disable="batchDisable"
        :drawer="batchDrawerOpen"
        :plasticsAllList="plasticsAllList"
        :plasticsList="plasticsList"
        :plasticsObj="plasticsObj"
        @close="changeBatchHidden"
        @onChange="changeBatchList"
    />
  </div>
</template>

<script>
import notifyMsgCm from "../notify/notifyMsg";
import { extend, get } from "lodash";
import {
  confirmCurePlan,
  confirmTextPlan,
  detailCurePlan,
  product_detail,
  PublicPlasticSheetList
} from "common/api/cases";
import BatchDrawer from "@/pages/cases/cases-detail/view3d/components/batch-drawer.vue";

export default {
  props: {
    environmene: String,
    cureNoteDetail: Object,
    caseDetail: Object,
    plasticsList: Array,
    plasticSheetObj: Object,
    // plasticsObj: Object,
    diagnosisDetail: Object,
    userDetail: Object,
    mod3DRecord: String,
    canEdit: Boolean,
    isEdit: Boolean,
    defaultOperateRemark: String,
    defaultVeneerRemark: String,
    defaultAmendOpinion: Boolean
  },
  data() {
    return {
      batchDetail: null,
      plasticsObj: null,
      plasticsAllList: [],
      batchDisable: false,
      productionContentList: [],
      batchDrawerOpen: false,
      nowProjectType: 1,
      amendOpinion: false,
      operateRemark: "",
      veneerRemark: "",
      showModal: false,
      modalType: "",
      confirmText: {
        edit: this.$t("casesDetail.casesDetail.bc"),
        info: this.$t("common.message.qd"),
        submit: this.$t("casesDetail.casesChildren.reviewPlan.rrpz")
      },
      upperBatchList: {},
      lowerBatchList: {},
      plasticsBatchList: [],
      copyPlasticsBatchList: [],
      materialData: [],
      autoSave: null,
      timer: 0,
      upSteps: [],
      downSteps: [],
      upSheetList: [],
      downSheetList: [],
      renderPlasticsNames: "",
      sheetList: [],
      downList: [],
      upList: [],
      atList: []
    };
  },
  watch: {
    veneerRemark(nval) {
      this.$emit("changeVeneerRemark", nval);
    },
    operateRemark(nval) {
      this.$emit("changeOperateRemark", nval);
    },
    amendOpinion(nval) {
      this.$emit("changeAmendOpinionShow", nval);
    },
    cureNoteDetail: {
      handler(nval) {
        this.productionContentList = nval.batchInfo.productionContentList;
        this.batchDetail = nval;
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    BatchDrawer,
    notifyMsgCm
  },
  computed: {
    buttonShow() {
      if (
          this.nowPort === "RDT" &&
          ["0", "2"].includes(this.cureNoteDetail["passFlag"])
      ) {
        return true;
      }
      if (
          ["SALES", "LIANSUO_CLINIC", "DOCTOR"].includes(this.nowPort) &&
          this.cureNoteDetail["passFlag"] === "0"
      ) {
        return true;
      }
    },
    approveShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      if (status == "to_doctor_confirm_plan" && this.nowPort === "DOCTOR") {
        return true;
      }
    },
    amendmentShow() {
      const status = this.caseDetail["curePlanInfo"].status;
      const irdtCanShow = [
        "to_approve_plan",
        "to_reconfirm_plan",
        "to_confirm_plan"
      ];
      const canShow = ["to_doctor_confirm_plan"];
      if (
          this.nowPort === "RDT" &&
          irdtCanShow.includes(status) &&
          ["0", "2"].includes(this.cureNoteDetail["passFlag"]) &&
          this.cureNoteDetail["generateFlag"] == "1" &&
          this.cureNoteDetail["historyFlag"] == "0"
      ) {
        return true;
      }
      if (["SALES", "LIANSUO_CLINIC", "DOCTOR"].includes(this.nowPort)) {
        if (canShow.includes(status)) {
          return true;
        }
      }
    },
    schemeType() {
      return this.$route.query.schemeType;
    },
    irdtStage() {
      return this.$route.query.stage;
    },
    nowPort() {
      return this.userDetail["kind"] || "DOCTOR";
    }
  },
  methods: {
    get,
    toHelp() {
      window.open("/helpCenter?type=21");
    },
    changeBatchList(list) {
      this.productionContentList = list;
      this.batchDrawerOpen = false;
      this.showBatchInfo();
    },

    changeBatchOpen(type, index) {
      this.batchDrawerOpen = true;
      this.batchDisable = this.modalType !== "edit";

      this.$refs.batchDrawerRef.changeActiveNames(type, index);
    },
    changeBatchHidden() {
      this.batchDrawerOpen = false;
    },
    renderBatchItems(jawList = [], allCount) {
      let list = [];
      jawList?.forEach((it, idx) => {
        const endBatch = jawList.slice(0, idx + 1);
        const startBatch = jawList.slice(idx, jawList.length + 1);
        const endCount = +endBatch.reduce((a, b) => +a + +b);
        const startCount = allCount - +startBatch.reduce((a, b) => +a + +b) + 1;
        list.push(endCount < startCount ? "0" : `${startCount}-${endCount}`);
      });
      return list;
    },
    callModalShow(type) {
      this.modalType = type;
      this.showModal = true;
    },
    // renderPlasticsNames() {
    //   // const result = this.plasticsBatchList.map(
    //   //   item => this.plasticSheetObj[item]
    //   // );
    //   const result = this.cureNoteDetail.batchInfo.productionContentList
    //     .map(item => {
    //       if (item.type !== "4") {
    //         return this.plasticsObj?.[item.plasticSheetId];
    //       }
    //     })
    //     .filter(Boolean);
    //   return Array.from(new Set(result)).join(" / ");
    // },
    startAutoSave() {
      this.autoSave = setInterval(() => {
        this.stagingData("0", true);
      }, 30000);
    },
    stopAutoSave() {
      clearInterval(this.autoSave);
      this.autoSave = null;
    },
    changeViewType() {
      const _isEdit = !this.isEdit;
      this.$emit("changeViewType", _isEdit);
    },
    goPage(url) {
      let query = this.$route.query;
      sessionStorage.removeItem("hideHead");
      return (
          url &&
          this.$router.push({
            path: url,
            query: extend(
                {},
                {caseId: query.caseId, curePlanId: query.curePlanId}
            )
          })
      );
    },
    confirmModal() {
      if (["edit", "info"].includes(this.modalType)) {
        this.plasticsBatchList = [...this.copyPlasticsBatchList];
        this.showModal = false;
      } else {
        let api;
        this.schemeType === "target"
            ? (api = confirmTextPlan)
            : (api = confirmCurePlan);
        const curePlanId = this.$route.query.curePlanId;
        if (!curePlanId) {
          this.showModal = false;
          return false;
        }
        const isExistData = this.materialData.filter(item =>
            this.copyPlasticsBatchList.includes(item.sheetCode)
        );
        const differentData = Array.from(new Set(this.copyPlasticsBatchList));

        // if (isExistData.length !== differentData.length) {
        //   return this.$message({
        //     message: this.$t("threeD.mpczbczczlb"),
        //     type: "warning"
        //   });
        // }
        // const _productionContentList=this.productionContentList
        api({
          id: curePlanId,
          approveResult: "1",
          productionContentList: this.productionContentList.sort(
              (a, b) => a.orderNo - b.orderNo
          )
          // toothBatchPlasticSheet: this.copyPlasticsBatchList.join(",")
        })
            .then(() => {
              this.showModal = false;
              this.$refs["notifyMsgCm"]
                  .open({
                    text: this.$t("casesDetail.casesChildren.common.czcg"),
                    type: "success"
                  })
                  .then(() => {
                    setTimeout(() => {
                      const {userInfo} = this.cureNoteDetail;
                      localStorage.setItem(
                          "windowName",
                          userInfo.realName + this.$t("common.common.blxq")
                      );
                      let query = this.$route.query;
                      this.goPage(
                          `/cases/detail?caseId=${query.caseId}&curePlanId=${query.curePlanId}`
                      );
                    }, 1000);
                  });
            })
            .catch(() => {
              this.showModal = false;
            });
      }
    },
    // 暂存 or 提交修改意见
    stagingData(type, isAutoSave = false) {
      let {ts, token} = this.$route.query;
      let obj = {
        type,
        approveResult: type,
        token
      };
      if (type !== "0") {
        if (this.operateRemark == "" && this.veneerRemark == "") {
          if (this.caseDetail["cureType"] === "E") {
            return this.$refs["notifyMsgCm"].open({
              text: this.$t("threeD.qzsxtyxfajxtj"),
              type: "warning"
            });
          } else {
            return this.$refs["notifyMsgCm"].open({
              text: this.$t("casesDetail.casesChildren.amendments.qxtx"),
              type: "warning"
            });
          }
        }
      }
      if (this.operateRemark !== "") {
        obj.suggest = this.operateRemark;
      } else {
        obj.suggest = "";
      }
      if (this.veneerRemark !== "") {
        obj.veneerSuggest = this.veneerRemark;
      } else {
        obj.veneerSuggest = "";
      }
      if (!ts) {
        obj.toothBatchPlasticSheet = this.copyPlasticsBatchList.join(",");
      }
      if (!ts) {
        obj.productionContentList = this.productionContentList.sort(
            (a, b) => a.orderNo - b.orderNo
        );
      }
      obj.productionContentList = obj.productionContentList.sort(
          (a, b) => a.orderNo - b.orderNo
      );
      obj.productionContentList.sort((a, b) => a.orderNo - b.orderNo);

      this.$emit("stagingData", obj, isAutoSave);
    },
    convertStepsToRanges(stepsArray) {
      if (stepsArray.length === 0) {
        return []; // 如果输入数组为空，返回空数组
      }

      let start = 1;
      const result = [];
      stepsArray.forEach(step => {
        const end = start + step - 1;
        result.push(`${start}-${end}`);
        start = end + 1;
      });

      return result;
    },
    countStep(total, batch) {
      const batchArr = batch?.split(",").map(v => Number(v)) || [];
      return batchArr?.map((v, index) => {
        const startBatch = batchArr.slice(index, batchArr.length + 1);
        const endBatch = batchArr.slice(0, index + 1);
        const startCount = total - startBatch.reduce((a, b) => a + b) + 1;
        const endCount = endBatch.reduce((a, b) => a + b);
        return endCount < startCount ? "0" : `${startCount}-${endCount}`;
      });
    },
    showBatchInfo(_plasticsObj) {
      const plasticsObj = _plasticsObj || this.plasticsObj;
      const {
        maxillarySteps,
        mandibularSteps,
        maxillary,
        mandibular
      } = this.cureNoteDetail?.batchInfo;
      const result = this.productionContentList
          .map(item => {
            if (item.type !== "4") {
              return plasticsObj?.[item.plasticSheetId];
            }
          })
          .filter(Boolean);
      this.renderPlasticsNames = Array.from(new Set(result)).join(" / ");
      const _ups = this.countStep(maxillary, maxillarySteps);

      const _downs = this.countStep(mandibular, mandibularSteps);

      const upList = [];
      const downList = [];
      const _atList = [];
      // const _ups=this.convertStepsToRanges(ups)
      // const _downs=this.convertStepsToRanges(downs)
      _ups.forEach((v, index) => {
        upList?.push([]);
        downList?.push([]);
        const ups = v?.split("-");
        const downs = _downs[index]?.split("-");
        this.productionContentList.forEach(item => {
          if (item.type !== "4") {
            if (item.position === "1") {
              if (Number(ups[0]) <= item.step && item.step <= Number(ups[1])) {
                upList?.[index]?.push(item);
              }
            }
            if (item.position === "2") {
              if (
                  Number(downs[0]) <= item.step &&
                  item.step <= Number(downs[1])
              ) {
                downList?.[index]?.push(item);
              }
            }
          } else {
            _atList.push(item);
          }
        });
      });
      const upSheetList = [];
      upList.forEach(v => {
        upSheetList.push(
            [...new Set(v.map(item => plasticsObj[item.plasticSheetId]))].join(
                "，"
            )
        );
      });
      const downSheetList = [];
      downList.forEach(v => {
        downSheetList.push(
            [...new Set(v.map(item => plasticsObj[item.plasticSheetId]))].join(
                "，"
            )
        );
      });
      this.atList = _atList;
      this.upList = upList;
      this.downList = downList;
      this.upSteps = _ups;
      this.downSteps = _downs;
      this.upSheetList = upSheetList;
      this.downSheetList = downSheetList;
    },
    changeBatchSelect(type, index, id) {
      // 根据类型选择对应的列表
      const list = type === "up" ? this.upList : this.downList;

      // 更新指定索引的子列表中的 plasticSheetId
      list[index].forEach(item => {
        item.plasticSheetId = id;
      });

      // 合并 upList 和 downList 并扁平化
      const upListFlat = this.upList.flat(2);
      const downListFlat = this.downList.flat(2);

      // 更新 productionContentList
      const _productionContentList = [...upListFlat, ...downListFlat, ...list];

      // 生成 renderPlasticsNames
      const result = _productionContentList
          .map(item => {
            if (item.type !== "4") {
              return this.plasticsObj?.[item.plasticSheetId];
            }
          })
          .filter(Boolean);
      this.renderPlasticsNames = Array.from(new Set(result)).join(" / ");

      // 更新 batchDetail
      this.batchDetail = {
        ...this.batchDetail,
        batchInfo: {
          ...this.batchDetail.batchInfo,
          productionContentList: _productionContentList
        }
      };
    }
  },

  mounted() {
    let {token} = this.$route.query;

    PublicPlasticSheetList({token}).then(data => {
      const plasticsObj = {};
      data.forEach(it => {
        plasticsObj[it.id] = it.name;
      });

      this.plasticsAllList = data;
      const sheetList = [];
      this.productionContentList.forEach(item => {
        if (item.type !== "4") {
          sheetList.push(item.plasticSheetId);
        }
      });
      let _list = [...this.plasticsList];
      data.forEach(item => {
        if (sheetList.includes(item.id)) {
          _list.push(item);
        }
      });
      _list = Array.from(new Map(_list.map(item => [item.id, item])).values());
      this.sheetList = [...new Set(_list)].sort(
          (a, b) => b.orderNo - a.orderNo
      );
      this.materialData = this.plasticsList;
      this.plasticsObj = plasticsObj;
      // if ("toothBatchPlasticSheet" in this.cureNoteDetail) {
      const list = this.cureNoteDetail["toothBatchPlasticSheet"] || "";
      this.plasticsBatchList = list?.split(",") || [];
      this.copyPlasticsBatchList = [...this.plasticsBatchList];
      const {
        toothPreDownJawBatchCount,
        toothPreUpJawBatchCount,
        correctStepDownjaw,
        correctStepUpjaw,
        suggest = "",
        veneerSuggest = ""
      } = this.cureNoteDetail;
      this.showBatchInfo(plasticsObj);
      this.operateRemark =
          this.defaultOperateRemark !== "" ? this.defaultOperateRemark : suggest;
      this.veneerRemark =
          this.defaultOperateRemark !== ""
              ? this.defaultVeneerRemark
              : veneerSuggest;
      this.amendOpinion = this.defaultAmendOpinion;
      const lower = toothPreDownJawBatchCount?.split(",");
      const upper = toothPreUpJawBatchCount?.split(",");
      this.lowerBatchList = this.renderBatchItems(lower, correctStepDownjaw);
      this.upperBatchList = this.renderBatchItems(upper, correctStepUpjaw);
      // } else {
      //   this.plasticsBatchList = [];
      // }
    });
  }
};
</script>

<style lang="scss" scoped>
.feedback {
  height: 100%;
  max-width: 444px;
  padding: 44px 30px 30px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  color: var(--textColor);
  position: relative;
  overflow: hidden;

  .revision-container {
    position: absolute;
    z-index: 8;
    top: 24px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    background-color: var(--feedback-message-background);
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .project-tabs {
      height: 40px;
      display: flex;
      background-color: var(--darkColor);
      border-radius: 4px 4px 0 0;

      > div {
        font-size: 14px;
        cursor: pointer;
        color: var(--textColor);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .left-active {
        background: var(--feedback-tabs-active-left-theme-imgs) no-repeat center;
        transform: translateY(1px) translateX(0.2px);
        background-size: 100% 100%;
        color: #fcc80e;
      }

      .right-active {
        background: var(--feedback-tabs-active-right-theme-imgs) no-repeat center;
        transform: translateY(1px);
        background-size: 100% 100%;
        color: #fcc80e;
      }

      .close-drawer {
        cursor: pointer;
        flex: none;
        width: 74px;
        height: 40px;
        text-align: right;
        //background: var(--close-drawer-img) no-repeat center;
        //background-size: 16px 16px;
        font-size: 26px;
        line-height: 40px;
      }

      .close-drawer:hover {
        color: #fcc80e !important;
      }
    }

    .close-revision {
      font-size: 24px;
      position: absolute;
      top: 12px;
      right: 20px;
      cursor: pointer;
    }

    .close-revision:hover {
      color: #fcc80e !important;
    }

    .revision-content,
    .revision-content-hide-radio {
      flex: 1;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      color: var(--textColor);
      word-break: break-all;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .flex-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 30px;

        .cure-plan-name {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;

          .user-name {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 20px;
            line-height: 28px;
          }

          span {
            font-size: 14px;
            line-height: 20px;
            color: #fcc80e;
            margin-left: 7px;
          }
        }

        .create-design-time {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .design-opinion {
          width: 100%;
          height: 180px;
          border-radius: 4px;
          border: 1px solid #bbbbbb;
          background-color: var(--backgroundColor);
          padding: 10px 16px;
          font-size: 14px;
          line-height: 20px;
          overflow-y: auto;
          flex-shrink: 0;
          white-space: pre-wrap;
        }

        .divider-line {
          width: 100%;
          height: 1px;
          background-color: #bbbbbb;
          margin: 30px 0;
          flex-shrink: 0;
        }

        .opinion-description {
          &-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 22px;
            justify-content: space-between;
            position: relative;

            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            .flex-left,
            .flex-right {
              display: flex;
              align-items: center;
            }

            .edit-3d-control {
              min-width: 104px;
              text-align: center;
              cursor: pointer;
              justify-self: flex-end;
              height: 28px;
              line-height: 28px;
              padding: 0 8px;
              border-radius: 4px;
              background-color: #fcc80e;
              color: #333333;
              font-size: 14px;
            }

            .help-icon {
              cursor: pointer;
              width: 16px;
              height: 16px;
              background: var(--help-icon) no-repeat center;
              background-size: 100% 100%;
              margin-left: 4px;
            }
          }

          .mod-3d-record {
            margin-top: 10px;

            .record-tips {
              font-size: 14px;
              color: var(--messageColor);
              line-height: 20px;
              margin-bottom: 4px;
            }

            .record-info {
              font-size: 14px;
              line-height: 20px;
              color: var(--textColor);
              white-space: pre-wrap;
            }
          }

          &-content {
            .edit-revision {
              box-sizing: border-box;
              margin-top: 10px;
              width: 100%;
              height: 180px;
              font-size: 14px;
              line-height: 20px;
              border-radius: 4px;
              padding: 10px 16px;
              border: 1px solid #bbbbbb;
              transition: linear 0.2s;

              &:focus {
                border: 1px solid #fcc80e;
              }
            }
          }

          .submit-tips,
          .irdt-tips {
            font-size: 14px;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }

      .flex-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        width: 100%;
        padding: 0 30px 30px;
        height: 36px;

        .working-area,
        .submit-revision {
          cursor: pointer;
          flex: 1;
          max-width: 156px;
          height: 36px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #333333;
        }

        .working-area {
          border: 1px solid #bbbbbb;
          background-color: #ffffff;
        }

        .submit-revision {
          margin-left: 32px;
          background-color: #fcc80e;
        }
      }
    }

    .revision-content-hide-radio {
      border-radius: 0px 4px 4px 4px;
    }
  }

  .feedback-content {
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .user_message {
      width: 100%;
      height: 88px;
      display: flex;
      margin-bottom: 30px;

      .user_pic {
        width: 0.88rem;
        height: 0.88rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 0.19rem;
        border-radius: 6px;
      }

      .user_content {
        flex: 1;

        .user_name {
          width: 230px;
          height: 25px;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 12px;
          color: var(--textColor);
        }

        .user_age {
          font-size: 14px;
          color: var(--messageColor);
          display: flex;
          align-items: center;

          span {
            margin-top: 2px;
          }
        }

        .user_info_icon {
          margin-left: -2px;
          width: 24px;
          height: 26px;
        }
      }
    }

    .case-number,
    .curenote-name,
    .plastics-name {
      color: var(--textColor);
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    .plastics-name {
      margin-bottom: 26px;

      .batch-info,
      .edit-batch {
        cursor: pointer;
        color: #fcc80e;
      }

      .edit-batch {
        margin: 0 12px;
      }
    }

    .project-tabs {
      height: 40px;
      display: flex;
      background-color: var(--darkColor);
      border-radius: 4px 4px 0 0;

      > div {
        font-size: 14px;
        cursor: pointer;
        color: var(--textColor);
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .left-active {
        background: var(--feedback-tabs-active-left-theme-imgs) no-repeat center;
        transform: translateY(1px);
        background-size: 100% 100%;
        color: #fcc80e;
      }

      .right-active {
        background: var(--feedback-tabs-active-right-theme-imgs) no-repeat center;
        transform: translateY(1px);
        background-size: 100% 100%;
        color: #fcc80e;
      }
    }

    .project-text {
      flex: 1;
      min-height: 200px;
      overflow: hidden auto;
      word-break: break-all;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 22px;
      padding: 16px;
      border: 1px solid #bbb;
      background-color: var(--feedback-message-background);
    }

    .default-project-text {
      border-radius: 4px;
    }
  }

  .feedback-footer {
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .submit-revision,
    .approval-programme {
      cursor: pointer;
      flex: 1;
      max-width: 156px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      border-radius: 6px;
      font-size: 16px;
      color: #333333;
    }

    .submit-revision {
      background-color: #ffffff;
      border: 1px solid #bbbbbb;
    }

    .approval-programme {
      margin-left: 32px;
      background-color: #FCC80E;
      color: #333333;
    }
  }

  .animation-change {
    cursor: pointer;
    margin-top: 24px;
    width: 176px;
    height: 42px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
  }

  .slide-fade-enter-active {
    transition: all 0.1s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(5px);
    opacity: 0;
  }
}

.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .plastics-modal,
  .submit-modal {
    width: 960px;
    max-height: 580px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #828282;
    background-color: var(--darkColor);

    .modal-header {
      padding: 0 20px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .close-modal {
        cursor: pointer;
        font-size: 24px;
      }
    }

    .modal-content {
      flex: 1;
      padding: 22px;
      overflow-y: auto;
      word-break: break-all;

      .bacth-current-title {
        display: flex;
        align-items: center;
        color: var(--textColor);
        font-size: 14px;
        line-height: 20px;

        .label {
          width: 80px;
          margin-right: 10px;
        }

        .value {
          display: flex;
          align-items: center;

          div {
            width: 135px;
            margin-right: 16px;
          }

          .batch_step {
            width: 100px;
          }
        }
      }

      .batch-content {
        margin-top: 26px;
        padding-left: 110px;

        .batch-item {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          color: var(--textColor);

          div {
            width: 150px;
            margin-right: 16px;
          }

          .batch_step {
            width: 100px;
          }

          .plastics {
            flex: 1;
            margin-right: 10px;
          }

          .plastics-select {
            width: 148px;

            :global {
              .ant-select-selector {
                font-size: 14px;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .submit-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      img {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;
      }

      .submit-title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .submit-tips {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        text-align: center;
        color: var(--textColor);
      }

      .plastics-wrap {
        width: 420px;
        min-height: 96px;
        border-radius: 8px;
        background-color: var(--backgroundColor);
        padding: 12px;
        backdrop-filter: blur(0px);

        .plastics-content {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          font-size: 14px;
          line-height: 20px;

          > div {
            flex-shrink: 0;
          }

          .label {
            max-width: 80px;
            margin-right: 20px;
          }

          .value {
            flex: 1;
            margin: 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .control {
            color: #FCC80E;

            span {
              cursor: pointer;
            }

            :first-child {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .modal-footer {
      height: 92px;
      display: flex;
      justify-content: center;
      align-items: center;

      .cancel,
      .confirm {
        cursor: pointer;
        min-width: 124px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #333333;
        font-size: 14px;
        background-color: #fcc80e;
        //background-color: var(--mainColor);
      }

      .cancel {
        background-color: #ffffff;
        border: 1px solid #bbbbbb;
        margin-right: 32px;
      }
    }
  }

  .submit-modal {
    width: 500px;
    height: 368px;
  }
}

/deep/ .sheet-select {
  width: 148px !important;
}

.plastics /deep/ .input::-webkit-input-placeholder {
  color: var(--messageColor);
  background-color: var(--backgroundColor);
}

.plastics /deep/ .el-select,
.plastics /deep/ .el-input__inner {
  background-color: var(--backgroundColor) !important;
  color: var(--textColor);
}

.plastics /deep/ .el-input .el-input--suffix {
  border-radius: 10px;
  //background: #58595A;
  background: red !important;
}

.plastics /deep/ .el-select-dropdown__wrap {
  background-color: var(--backgroundColor);
  border: none;
}

.plastics /deep/ .el-scrollbar {
  border-radius: 12px;
  background-color: var(--backgroundColor);
  border: none;
}

.plastics /deep/ .el-select-dropdown__item {
  color: var(--textColor) !important;
  z-index: 9999;
}

.plastics /deep/ .el-select-dropdown__list {
  background-color: var(--backgroundColor);
}

.plastics /deep/ .el-select-dropdown {
  background-color: var(--backgroundColor);

  border: none;
}

.plastics /deep/ .el-select-dropdown__item.hover {
  background: var(--darkColor) !important;
  color: #fcc80e !important;
}

.plastics /deep/ .popper__arrow::after {
  color: var(--darkColor);
  border-bottom-color: var(--backgroundColor);
}

.plastics /deep/ .el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: var(--backgroundColor);
}

.batch-tips {
  font-size: 12px;
  padding-left: 80px;
  line-height: 20px;
  color: var(--messageColor);
  margin-top: 20px;
}

.plastics-tips {
  color: var(--messageColor);
  font-size: 12px;
  line-height: 16px;
}
</style>
